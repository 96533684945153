<template>
  <div class="special-page" :style="'background-image: url(' + pageBackground + ')'">
    <div class="page-header">
      <div class="left" @click="back">
        <el-icon class="el-icon-back"></el-icon>
      </div>
      <div class="right">
        {{this.specialInfo.title}}----{{updatetime(nowDate)}}
      </div>
    </div>
    <div class="page-content">
      <div class="menu-panel">
        <swiper class="swiper-container" ref="mySwiper" :options="swiperOptions">
          <swiper-slide :class="[activeItem === item.m_id ? 'active' : '', 'swiper-slide']" v-for="item in specialInfo.classify_list" :key="item.id">
            <img :src="item.icon" @click="toggleActive(item)"/>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev">
          <img src="../assets/img/left.png"/>
        </div>
        <div class="swiper-button-next">
          <img src="../assets/img/right.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Special',
  data () {
    return {
      pageBackground: '',
      activeItem: '',
      specialInfo: {
        id: 0,
        m_id: '',
        tid: '',
        title: '',
        icon: '',
        background: '',
        classify_list: []
      },
      // 当前时间
      nowDate: new Date(),
      // swiper
      swiperOptions: {
        slidesPerView: 4, // 显示个数
        direction: 'horizontal',
        spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    this.getSpecial()
  },
  methods: {
    async getSpecial () {
      const { data: res } = await this.$http.get('/special', { params: { special_id: this.$route.params.special_id } })
      if (res.code === 200) {
        this.specialInfo.id = res.data.id
        this.specialInfo.m_id = res.data.m_id
        this.specialInfo.tid = res.data.tid
        this.specialInfo.title = res.data.title
        this.specialInfo.icon = res.data.icon
        this.specialInfo.background = res.data.background
        this.pageBackground = res.data.background
        this.specialInfo.classify_list = res.data.classify_list
        this.$nextTick(() => {
          this.swiper.params.slidesPerView = res.data.block_num
          this.swiper.update()
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    back () {
      this.$router.push('/campus/' + this.$route.params.campus_id)
    },
    updatetime (value) {
      return this.$moment(value).format('LTS')
    },
    // 点击进入专题
    toggleActive (item) {
      console.log(item)
      if (this.activeItem && this.activeItem !== item.m_id && parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = item.m_id
        this.pageBackground = item.background
      } else if (this.activeItem && this.activeItem === item.m_id && parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = ''
        this.pageBackground = this.specialInfo.background
        this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + this.$route.params.special_id + '/classify/' + item.m_id)
      } else if (parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = item.m_id
        this.pageBackground = item.background
      } else {
        this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + this.$route.params.special_id + '/classify/' + item.m_id)
      }
    }
  },
  mounted () {
    const _this = this
    this.timer = setInterval(() => {
      _this.nowDate = new Date()
    }, 1000)
  },
  beforeDestroy () { // 生命周期实例销毁之前
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  }
}
</script>

<style scoped>
  .special-page{
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
  }
  .page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;
  }
  .page-header .left{
    width: 50px;
    height: 50px;
    background-color: #FBAE17;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
  }
  .left img{
    width: 100%;
  }
  .right{
    color: #FFFFFF;
    background-color: rgba(0,0,0,0.2);
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 15px;
  }
  .page-content{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-panel{
    width: 90%;
    height: 100%;
    position: relative;
  }
  .swiper-container {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  /deep/.swiper-wrapper{
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: auto;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 0.5em;
    cursor: pointer;
  }
  .swiper-slide.active{
    transform: scale(1.05);
    transition: all .3s;
    border: 4px solid #FBAE17;
    padding: 2px;
    background-color: rgba(255,255,255,0);
  }
  .swiper-slide img{
    width: 100%;
    height: 100%;
    border-radius: 0.5em;
  }
  .swiper-button-prev:after{
    content: none;
  }
  .swiper-button-prev{
    width: 5%;
    height: auto;
    left: 20px;
    cursor: pointer;
  }
  .swiper-button-prev img{
    width: 100%;
    height: 100%;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-next{
    width: 5%;
    height: auto;
    right: 20px;
    cursor: pointer;
  }
  .swiper-button-next img{
    width: 100%;
    height: auto;
  }
</style>
